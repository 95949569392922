.footer-shape{
    position: absolute;
    left: 0;
    width: 20rem;
    z-index: -10;
}
.footer-icons{
    padding-top: 2.7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}
.footer-icons>div{
    display: flex;
    gap: 1rem;
}
.social-icons{
    fill: #ffffff;
    width: .4rem;
    height: .4rem;
}