.hero-cont{
    margin-top: 1.6rem;
    display: flex;
    justify-content: space-between;
}
.hero-cont>:nth-child(1){
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.hero-cont>:nth-child(1)>div{
    display: flex;
    gap: .3rem;
}
.hero-cont>:nth-child(1)>div>button{
    background: var(--btnGrad);
    border: none;
    font-size: .25rem;
    color: #ffffff;
    padding: .2rem .4rem;
    border-radius: 10px;
    cursor: pointer;
}
.hero-cont>:nth-child(1)>div>:nth-child(2){
    background: transparent;
    text-decoration: underline;
}
.hero-cont>:nth-child(2){
    width: 52%;
    position: relative;
}
.hero-cont>:nth-child(2)>img{
    width: 9rem;
    position: absolute;
    transform: translateY(-1rem);

}