.questions-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 1.5rem;
    position: relative;
}
.questions-cont>:nth-child(1){
    text-align: center;
    width: 40%;
}
.questions-cont>:nth-child(2){
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}
.questions-cont>:nth-child(2)>:nth-child(1){
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}
.questions-cont>:nth-child(2)>:nth-child(2){
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: .2rem;
}
h6{
    font-size: .2rem;
    margin: 0;
}
.question-box{
background-color: #ffffff;
border-radius: 10px;
padding: .3rem .4rem;
-webkit-box-shadow: 3px 16px 41px -6px rgba(0,0,0,0.05);
-moz-box-shadow: 3px 16px 41px -6px rgba(0,0,0,0.05);
box-shadow: 3px 16px 41px -6px rgba(0,0,0,0.05);
display: flex;
align-items: center;
justify-content: space-between;
}
.arrow-down{
    width: .3rem;
    height: .3rem;
    fill: var(--mainColor);
    cursor: pointer;
}
.question-shape-left{
    position: absolute;
    width: 1rem;
    left: 0;
    top: 0;
    transform: translateX(-1rem) scaleY(-1);
}
.question-shape-right{
    position: absolute;
    width: 1rem;
    right: 0;
    bottom: 0;
    transform: translateX(1rem) scaleY(-1);
}