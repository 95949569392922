.pricing-cont{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 4.5rem;
}
.pricing-cont>:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 35%;
    margin-top: 1rem;
}
.pricing-cont>:nth-child(2){
    width: 41.5%;
    display: flex;
}
.pricing-cont>:nth-child(2)>img{
    height: 3.2rem;
    margin-top: 1.5rem;
}
.pricing-cont>:nth-child(2)>div{
    display: flex;
    flex-direction: column;
}
.pricing-cont>:nth-child(2)>div>:nth-child(1){
    height: 3.2rem;
}
.pricing-cont>:nth-child(2)>div>:nth-child(2){
    height: 3.2rem;
}