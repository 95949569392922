.shape1{
    width: 20rem;
    height: 11.5rem;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -10;
}
.nav-bar{
    display: flex;
}
.Nav-logo{
    height: .8rem;
    margin-right: auto;
    
}
.nav-bar>ul{
    display: flex;
    list-style: none;
    align-items: center;
    color: #ffffff;
    font-size: .23rem;
}
.nav-bar>:nth-child(2){
    gap: .6rem;
}
.nav-bar>:nth-child(3){
    gap: .6rem;
    margin-left: .8rem;
}
.nav-bar>:nth-child(3)>:nth-child(2){
    background-color: var(--mainColor);
    padding: .15rem .4rem;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    margin: 0;
}
.nav-bar>:nth-child(3)>:nth-child(2):hover{
    background-color: #204abd;
}
.nav-bar>ul>li:hover{
    cursor: pointer;
    border-bottom: 3px solid #ffffff;
    margin-top: 5px;
    padding-bottom: 2px;
}